import React, { useState, useCallback, useEffect, useRef, useReducer, useMemo } from 'react';
import GridItem from './GridItem';
import MenuButtons from './MenuButtons';
import BottomMenuButtons from './BottomMenuButtons';
import AIReorganize from './AIReorganize';
import LogoutConfirmation from './LogoutConfirmation';
import AIFeedbackPopup from './AIFeedbackPopup';
import { ReactComponent as LogoIcon } from '../src/assets/icons/instagram.svg';
import FirstUploadPopup from './FirstUploadPopup';
import ErrorPopup from './ErrorPopup';
import UploadProgressBar from './UploadProgressBar';
import ErrorBoundary from './ErrorBoundary';
import { generateFileIdentifier } from './utils/fileIdentifier';
import Notification from './components/Notification';
import { saveState, loadState } from './utils/localStorage';

const API_TOKEN = 'IGQWRQYVBfSk1OU0JIMzBCTE93ckUyOUxRVERSdzlMdnpnaWhCY3FncmV1eVlzVjQzNU9EaVBxU2VSdF9nYy14YU5ZAbERXdzVRMDhVMjRGdU5uYkp4Q2JUTy1jWFVpSlJuN3BaMG01QWZAQcG9RbHNuVUR3WWVybkEZD';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const App = () => {
  const savedState = loadState();

  const [isDarkMode, setIsDarkMode] = useState(savedState?.isDarkMode ?? false);
  const [imagesLoaded, setImagesLoaded] = useState(savedState?.imagesLoaded ?? false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [undoHistory, setUndoHistory] = useState([]);
  const [redoHistory, setRedoHistory] = useState([]);
  const [profileInfo, setProfileInfo] = useState(savedState?.profileInfo ?? null);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showAIFeedback, setShowAIFeedback] = useState(false);
  const [fetchedInstagramImages, setFetchedInstagramImages] = useState(savedState?.fetchedInstagramImages ?? []);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showFirstUploadPopup, setShowFirstUploadPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [hasAttemptedUpload, setHasAttemptedUpload] = useState(false);
  const [gridItems, setGridItems] = useState(savedState?.gridItems ?? Array(12).fill({ type: 'empty', isCustom: false }));
  const [hasShownUploadInfo, setHasShownUploadInfo] = useState(savedState?.hasShownUploadInfo ?? false);
  const [bumpedImages, setBumpedImages] = useState(savedState?.bumpedImages ?? []);
  const [uploadedFiles, setUploadedFiles] = useState(savedState?.uploadedFiles ?? []);
  const [notificationMessage, setNotificationMessage] = useState('');

  const prevGridItems = usePrevious(gridItems);

  const hasEnoughCustomImages = useMemo(() => {
    return gridItems.filter(item => item && item.isCustom && item.type !== 'empty').length >= 2;
  }, [gridItems]);

  useEffect(() => {
    if (prevGridItems !== gridItems) {
      console.log('gridItems changed:', gridItems);
    }
  }, [gridItems, prevGridItems]);

  useEffect(() => {
    saveState({
      isDarkMode,
      imagesLoaded,
      profileInfo,
      fetchedInstagramImages,
      gridItems,
      hasShownUploadInfo,
      bumpedImages,
      uploadedFiles
    });
  }, [isDarkMode, imagesLoaded, profileInfo, fetchedInstagramImages, gridItems, hasShownUploadInfo, bumpedImages, uploadedFiles]);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkMode(darkModeMediaQuery.matches);

    const handleChange = (e) => setIsDarkMode(e.matches);
    darkModeMediaQuery.addListener(handleChange);

    return () => darkModeMediaQuery.removeListener(handleChange);
  }, []);

  useEffect(() => {
    const preventDefault = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e) => {
      preventDefault(e);
      // Optional: You can add a visual or audio cue here to indicate that
      // dropping outside the grid is not allowed
    };

    document.addEventListener('dragenter', preventDefault);
    document.addEventListener('dragover', preventDefault);
    document.addEventListener('dragleave', preventDefault);
    document.addEventListener('drop', handleDrop);

    return () => {
      document.removeEventListener('dragenter', preventDefault);
      document.removeEventListener('dragover', preventDefault);
      document.removeEventListener('dragleave', preventDefault);
      document.removeEventListener('drop', handleDrop);
    };
  }, []);

  const handleAddMediaClick = useCallback(() => {
    if (!hasShownUploadInfo) {
      setShowFirstUploadPopup(true);
    } else {
      document.getElementById('file-input').click();
    }
  }, [hasShownUploadInfo]);

  const updateGridItemsWithHistory = useCallback((newGridItems, newImagesLoaded = imagesLoaded) => {
    setUndoHistory(prevHistory => [...prevHistory, { gridItems, imagesLoaded }]);
    setGridItems(newGridItems);
    setImagesLoaded(newImagesLoaded);
    setRedoHistory([]);
  }, [gridItems, imagesLoaded]);

  const handleUndo = useCallback(() => {
    if (undoHistory.length > 0) {
      const previousState = undoHistory[undoHistory.length - 1];
      setRedoHistory(prevRedoHistory => [{ gridItems, imagesLoaded }, ...prevRedoHistory]);
      setGridItems(previousState.gridItems);
      setImagesLoaded(previousState.imagesLoaded);
      setUndoHistory(prevHistory => prevHistory.slice(0, -1));
    }
  }, [undoHistory, gridItems, imagesLoaded]);

  const handleRedo = useCallback(() => {
    if (redoHistory.length > 0) {
      const nextState = redoHistory[0];
      setUndoHistory(prevUndoHistory => [...prevUndoHistory, { gridItems, imagesLoaded }]);
      setGridItems(nextState.gridItems);
      setImagesLoaded(nextState.imagesLoaded);
      setRedoHistory(prevRedoHistory => prevRedoHistory.slice(1));
    }
  }, [redoHistory, gridItems, imagesLoaded]);

  const updateGridItem = useCallback((index, item) => {
    updateGridItemsWithHistory(prev => {
      const newItems = [...prev];
      newItems[index] = item || { type: 'empty', isCustom: true };
      return newItems;
    });
  }, [updateGridItemsWithHistory]);

  const handleDragStart = useCallback((index) => {
    // You can add any necessary logic here
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

const handleDrop = useCallback((sourceIndex, targetIndex, file) => {
  console.log('handleDrop called with:', { sourceIndex, targetIndex, file });
  console.log('Current gridItems:', gridItems);

  // First, check if the target index is out of bounds
  if (targetIndex < 0 || targetIndex >= gridItems.length) {
    console.error('Invalid target index');
    return;
  }

  if (file) {
    // Handle file drop
    const fileId = generateFileIdentifier(file);
    const isDuplicate = gridItems.some(item => 
      item.isCustom && generateFileIdentifier(item) === fileId
    );

    if (isDuplicate) {
      setNotificationMessage("Duplicate file was ignored");
      setTimeout(() => setNotificationMessage(''), 2000);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      updateGridItemsWithHistory(prev => {
        const newItems = [...prev];
        const newItem = {
          type: file.type.startsWith('image/') ? 'image' : 'video',
          content: e.target.result,
          isCustom: true,
          videoFile: file.type.startsWith('video/') ? file : null,
          videoUrl: file.type.startsWith('video/') ? URL.createObjectURL(file) : null,
          name: file.name,
          size: file.size,
          lastModified: file.lastModified
        };

        // If the target cell is empty or not custom, replace it
        // Otherwise, insert the new item and shift others
        if (!newItems[targetIndex].isCustom) {
          newItems[targetIndex] = newItem;
        } else {
          newItems.splice(targetIndex, 0, newItem);
          newItems.pop(); // Remove the last item to keep the grid size constant
        }
        
        return newItems;
      });
    };
    reader.readAsDataURL(file);
  } else if (sourceIndex !== null && sourceIndex !== targetIndex) {
    // Handle drag and drop between grid items
    updateGridItemsWithHistory(prev => {
      const newItems = [...prev];
      const [movedItem] = newItems.splice(sourceIndex, 1);
      newItems.splice(targetIndex, 0, movedItem);
      return newItems;
    });
  }
}, [gridItems, updateGridItemsWithHistory, setNotificationMessage]);
  
const handleMultipleFileUpload = useCallback((files) => {
  if (!files || files.length === 0) return;

   if (files.length > 6) {
    setErrorMessage('You can only upload up to 6 files at a time.');
    setShowErrorPopup(true);
    return;
  }

  setIsUploading(true);
  setUploadProgress(0);

  const newFiles = [];
  const ignoredFiles = [];

  // Get the file identifiers of currently displayed custom items
  const currentlyDisplayedFiles = gridItems
    .filter(item => item.isCustom && item.type !== 'empty')
    .map(item => generateFileIdentifier(item));

  Array.from(files).forEach(file => {
    const fileId = generateFileIdentifier(file);
    if (!currentlyDisplayedFiles.includes(fileId)) {
      newFiles.push(file);
    } else {
      ignoredFiles.push(file);
    }
  });

  if (ignoredFiles.length > 0) {
    const message = ignoredFiles.length === 1 
      ? "Duplicate file was ignored" 
      : "Duplicate files were ignored";
    setNotificationMessage(message);
    setTimeout(() => setNotificationMessage(''), 2000);
  }

  const uploadPromises = newFiles.map((file) => {
    return new Promise((resolve, reject) => {
      if (!file.type.startsWith('image/') && !file.type.startsWith('video/')) {
        reject(new Error(`File ${file.name} is not an image or video.`));
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          type: file.type.startsWith('image/') ? 'image' : 'video',
          content: e.target.result,
          isCustom: true,
          videoFile: file.type.startsWith('video/') ? file : null,
          videoUrl: file.type.startsWith('video/') ? URL.createObjectURL(file) : null,
          name: file.name,
          size: file.size,
          lastModified: file.lastModified
        });
      };
      reader.onerror = () => reject(new Error(`Failed to read file ${file.name}`));
      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100 / files.length;
          setUploadProgress(prevProgress => prevProgress + progress);
        }
      };
      reader.readAsDataURL(file);
    });
  });

  Promise.all(uploadPromises)
    .then(newItems => {
      updateGridItemsWithHistory(prev => {
        const updatedItems = prev.map(item => 
          item.type === 'empty' && item.isCustom ? newItems.shift() || item : item
        );
        const remainingNewItems = [...newItems, ...updatedItems].slice(0, 12);
        const bumpedItems = remainingNewItems.slice(12);
        setBumpedImages(prevBumped => [...bumpedItems, ...prevBumped]);
        return remainingNewItems;
      });
    })
    .catch(error => {
      setErrorMessage(error.message);
      setShowErrorPopup(true);
    })
    .finally(() => {
      setIsUploading(false);
      setUploadProgress(0);
    });
}, [updateGridItemsWithHistory, gridItems]);

const reopenFileInput = useCallback(() => {
  document.getElementById('file-input').click();
}, []);

const handleFileUpload = useCallback((index, fileOrEvent) => {
  let file;
  if (fileOrEvent instanceof File) {
    file = fileOrEvent;
  } else if (fileOrEvent.target && fileOrEvent.target.files) {
    file = fileOrEvent.target.files[0];
  } else {
    console.error('Invalid file input');
    return;
  }

  if (!hasAttemptedUpload) {
    setShowFirstUploadPopup(true);
    setHasAttemptedUpload(true);
    return;
  }

  if (file.type.startsWith('image/')) {
    const reader = new FileReader();
    reader.onload = (e) => {
      updateGridItem(index, { type: 'image', content: e.target.result, isCustom: true });
    };
    reader.readAsDataURL(file);
  } else if (file.type.startsWith('video/')) {
    const videoUrl = URL.createObjectURL(file) + '#t=0.1';
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.muted = true;
    video.playsInline = true;

    video.onloadedmetadata = () => {
      video.currentTime = 0.2;
      video.onseeked = () => {
        const canvas = document.createElement('canvas');
        canvas.width = 112; // Reduced size
        canvas.height = 112; // Reduced size
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        
        canvas.toBlob((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            updateGridItem(index, { 
              type: 'video', 
              content: reader.result,
              thumbnail: reader.result, // Add thumbnail
              videoFile: file, 
              videoUrl: videoUrl,
              isCustom: true 
            });
          };
          reader.onerror = (readerError) => {
            console.error('FileReader error:', readerError);
            updateGridItem(index, { 
              type: 'error', 
              content: 'Error processing video', 
              errorDetails: `FileReader error: ${readerError.message}`,
              isCustom: true 
            });
          };
          reader.readAsDataURL(blob);
        }, 'image/jpeg', 0.7); // Reduced quality
      };
    };

    video.onerror = (e) => {
      console.error('Video error:', e, video.error);
      updateGridItem(index, { 
        type: 'error', 
        content: 'Error loading video', 
        errorDetails: `Video error: ${video.error ? video.error.message : 'Unknown error'}. File type: ${file.type}`,
        isCustom: true 
      });
    };

    video.src = videoUrl;
  } else {
    updateGridItem(index, { 
      type: 'error', 
      content: 'Unsupported file type', 
      errorDetails: `File type: ${file.type}`,
      isCustom: true 
    });
  }
}, [updateGridItem, hasAttemptedUpload]);

  const addNewBox = useCallback(() => {
    updateGridItemsWithHistory(prev => {
      const newBox = { type: 'empty', isCustom: true };
      return [newBox, ...prev.slice(0, 11)];
    });
  }, [updateGridItemsWithHistory]);

  const fetchImages = useCallback(async () => {
    console.log('Fetching images...');
    try {
      // Fetch user profile information
      console.log('Fetching profile data...');
      const profileResponse = await fetch(`https://graph.instagram.com/me?fields=id,username,account_type,media_count&access_token=${API_TOKEN}`);
      const profileData = await profileResponse.json();
      console.log('Profile data:', profileData);

      if (profileData.error) {
        throw new Error(profileData.error.message);
      }

      setProfileInfo(profileData);

      // Fetch media
      console.log('Fetching media data...');
      const mediaResponse = await fetch(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,thumbnail_url,permalink&access_token=${API_TOKEN}`);
      const mediaData = await mediaResponse.json();
      console.log('Media data:', mediaData);

      if (mediaData.error) {
        throw new Error(mediaData.error.message);
      }

    const images = mediaData.data
      .filter(item => 
        (item.media_type === 'IMAGE' || item.media_type === 'CAROUSEL_ALBUM') ||
        (item.media_type === 'VIDEO' && !item.permalink.includes('/reel/'))
      )
      .map(item => ({
        type: item.media_type.toLowerCase(),
        content: item.media_type === 'VIDEO' ? item.thumbnail_url : item.media_url,
        isCustom: false
      }));

    setFetchedInstagramImages(images);  // Set the state
    return images;  // Return the images
  } catch (error) {
    console.error('Error fetching Instagram data:', error);
    return [];
  }
}, []);

const initialLoadFeed = useCallback(async () => {
  const images = await fetchImages();
  
  updateGridItemsWithHistory(prev => {
    // Filter out custom uploads (images or videos)
    const customUploads = prev.filter(item => item.isCustom && item.type !== 'empty');
    
    if (customUploads.length === 0) {
      // If there are no custom uploads, leave the first cell empty and fill 2-12 with Instagram images
      return [{ type: 'empty', isCustom: true }, ...images.slice(0, 11)];
    } else {
      // Create a new array with custom uploads at the beginning
      const newGridItems = [...customUploads];
      
      // Fill the remaining slots with Instagram images
      const remainingSlots = 12 - customUploads.length;
      images.slice(0, remainingSlots).forEach(image => {
        newGridItems.push(image);
      });
      
      return newGridItems;
    }
  }, true);
  
  setImagesLoaded(true);
}, [fetchImages, updateGridItemsWithHistory]);

const refreshFeed = useCallback(async () => {
  const newImages = await fetchImages();
  
  updateGridItemsWithHistory(prev => {
    // Filter out custom uploads (images or videos)
    const customUploads = prev.filter(item => item.isCustom && item.type !== 'empty');
    
    if (customUploads.length === 0) {
      // If there are no custom uploads, leave the first cell empty and fill 2-12 with Instagram images
      return [{ type: 'empty', isCustom: true }, ...newImages.slice(0, 11)];
    } else {
      // Create a new array with custom uploads at the beginning
      const updatedItems = [...customUploads];
      
      // Fill the remaining slots with new Instagram images
      const remainingSlots = 12 - customUploads.length;
      newImages.slice(0, remainingSlots).forEach(image => {
        updatedItems.push(image);
      });
      
      return updatedItems;
    }
  });

  setImagesLoaded(true);
}, [fetchImages, updateGridItemsWithHistory]);
  
const loadOrRefreshFeed = useCallback(async () => {
  setIsLoading(true);
  try {
    const images = await fetchImages();
    updateGridItemsWithHistory(prev => {
      const customItems = prev.filter(item => item.isCustom && item.type !== 'empty');
      const newItems = [...customItems, ...images];
      const bumpedItems = newItems.slice(12);
      setBumpedImages(prevBumped => [...bumpedItems, ...prevBumped]);
      return newItems.slice(0, 12);
    });
  } finally {
    setIsLoading(false);
    setImagesLoaded(true);
  }
}, [fetchImages, updateGridItemsWithHistory]);
  
const handleDelete = useCallback((index) => {
  updateGridItemsWithHistory(prev => {
    const newItems = [...prev];
    const deletedItem = newItems[index];
    if (deletedItem.isCustom) {
      const fileId = generateFileIdentifier(deletedItem);
      setUploadedFiles(prevFiles => prevFiles.filter(id => id !== fileId));
    }
    
    const customItems = newItems.filter(item => item.isCustom && item.type !== 'empty');
    const fetchedItems = newItems.filter(item => !item.isCustom);
    
    // Remove the item at the specified index
    newItems.splice(index, 1);
    
    // If there are bumped images, add one to the end
    if (bumpedImages.length > 0) {
      const [bumpedImage, ...remainingBumpedImages] = bumpedImages;
      newItems.push(bumpedImage);
      setBumpedImages(remainingBumpedImages);
    } else if (fetchedItems.length < 12) {
      // If no bumped images and we have less than 12 fetched images, add a fetched image
      const nextFetchedImage = fetchedInstagramImages[fetchedItems.length];
      if (nextFetchedImage) {
        newItems.push(nextFetchedImage);
      } else {
        // If we don't have any more fetched images, add an empty cell
        newItems.push({ type: 'empty', isCustom: false });
      }
    }
    
    // If we've deleted the last custom item, ensure all cells are filled with fetched images
    if (customItems.length === 1 && index === newItems.findIndex(item => item.isCustom)) {
      return fetchedInstagramImages.slice(0, 12);
    }
    
    // Ensure the order of fetched images is maintained
    const customItemCount = newItems.filter(item => item.isCustom).length;
    const fetchedItemsInOrder = fetchedInstagramImages.slice(0, 12 - customItemCount);
    
    // Combine custom items with fetched items in the correct order
    const finalItems = newItems.filter(item => item.isCustom);
    while (finalItems.length < 12) {
      finalItems.push(fetchedItemsInOrder[finalItems.length - customItemCount]);
    }
    
    return finalItems;
  });
}, [updateGridItemsWithHistory, bumpedImages, fetchedInstagramImages]);

const handleAIReorganize = useCallback(async () => {
  if (!hasEnoughCustomImages) {
    return;
  }
  setIsProcessing(true);
  try {
    const { items: reorganizedItems } = await AIReorganize({ gridItems });
    updateGridItemsWithHistory(reorganizedItems);
  } catch (error) {
    console.error('Error during AI reorganization:', error);
    // Handle error (show error message to user)
  } finally {
    setIsProcessing(false);
  }
}, [gridItems, hasEnoughCustomImages, updateGridItemsWithHistory]);

  const closeAIFeedback = useCallback(() => {
    setShowAIFeedback(false);
  }, []);
  
  const toggleTheme = useCallback(() => setIsDarkMode(prev => !prev), []);

  const handleLogout = useCallback(() => {
    setShowLogoutConfirmation(true);
  }, []);

  const confirmLogout = useCallback(() => {
    setProfileInfo(null);
    setGridItems(Array(12).fill({ type: 'empty', isCustom: true }));
    setImagesLoaded(false);
    setShowLogoutConfirmation(false);
  }, []);

  const cancelLogout = useCallback(() => {
    setShowLogoutConfirmation(false);
  }, []);

return (
  <ErrorBoundary>
    <div 
      className={`min-h-screen ${isDarkMode ? 'bg-black text-white' : 'bg-white text-black'}`}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
          handleMultipleFileUpload(e.dataTransfer.files);
        }
      }}
    >
      <div className="container mx-auto p-3 max-w-2xl">
        <div className="flex flex-col items-center mb-6">
          <LogoIcon className={`w-12 h-12 mb-2 mt-2 ${isDarkMode ? 'text-white fill-white' : 'text-white fill-white'}`} />
          <span className="text-lg font-semibold">
            {profileInfo ? `@${profileInfo.username}` : '@nextpostdemo'}
          </span>
        </div>
        <MenuButtons 
          isDarkMode={isDarkMode} 
          imagesLoaded={imagesLoaded}
          loadOrRefreshFeed={loadOrRefreshFeed}
          handleAIReorganize={handleAIReorganize}
          isProcessing={isProcessing}
          isLoading={isLoading}
          isRefreshing={isRefreshing}
          hasEnoughCustomImages={hasEnoughCustomImages}
          onAddMedia={handleAddMediaClick}
          hasShownUploadInfo={hasShownUploadInfo}
          setHasShownUploadInfo={setHasShownUploadInfo}
        />
        <div className="grid grid-cols-3 gap-0.5 my-0">
          {gridItems.map((item, index) => (
            <GridItem
              key={index}
              index={index}
              content={item}
              isDarkMode={isDarkMode}
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onDelete={handleDelete}
            />
          ))}
        </div>
        <BottomMenuButtons 
          isDarkMode={isDarkMode} 
          toggleTheme={toggleTheme} 
          handleUndo={handleUndo}
          handleRedo={handleRedo}
          canUndo={undoHistory.length > 0}
          canRedo={redoHistory.length > 0}
          handleLogout={handleLogout}
        />
        {showLogoutConfirmation && (
          <LogoutConfirmation 
            onConfirm={confirmLogout} 
            onCancel={cancelLogout} 
            isDarkMode={isDarkMode}
          />
        )}
        {showFirstUploadPopup && (
          <FirstUploadPopup 
            onClose={() => {
              setShowFirstUploadPopup(false);
              setHasShownUploadInfo(true);
              document.getElementById('file-input').click();
            }}
            isDarkMode={isDarkMode}
          />
        )}
        {showErrorPopup && 
          <ErrorPopup 
            message={errorMessage} 
            onClose={() => setShowErrorPopup(false)} 
            isDarkMode={isDarkMode} 
            customAction={reopenFileInput}
          />
        }
        {showAIFeedback && (
          <AIFeedbackPopup 
            onClose={closeAIFeedback}
            isDarkMode={isDarkMode}
          />
        )}
        <input 
          type="file" 
          id="file-input"
          className="hidden" 
          onChange={(e) => handleMultipleFileUpload(e.target.files)} 
          multiple 
          accept="image/*,video/*"
        />
        <Notification message={notificationMessage} />
      </div>
    </div>
  </ErrorBoundary>
);
};

export default App;