import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as TrashIcon } from '../src/assets/icons/trash.svg';

const GridItem = React.memo(({ content, isDarkMode, index, onDragStart, onDragOver, onDrop, onDelete }) => {
  const [isHovered, setIsHovered] = useState(false);
  const timeoutRef = useRef(null);

  const showTrashButton = content && content.isCustom && content.type !== 'empty';

  useEffect(() => {
    if (isHovered) {
      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      // Set a new timeout
      timeoutRef.current = setTimeout(() => {
        setIsHovered(false);
      }, 3000);
    }
    // Cleanup function
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isHovered]);

  const handleMouseEnter = () => {
    if (content && content.isCustom) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

const handleDragOver = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const handleDrop = (e) => {
  e.preventDefault();
  e.stopPropagation();
  const sourceIndex = e.dataTransfer.getData('text/plain');
  const file = e.dataTransfer.files[0];
  onDrop(sourceIndex ? parseInt(sourceIndex, 10) : null, index, file);
};

  const renderContent = () => {
    if (!content || content.type === 'empty') {
      return null;
    }
    if (content.type === 'error') {
      return (
        <div className="text-red-500 text-xs p-2 text-center">
          <p>{content.content}</p>
          <p className="mt-1">{content.errorDetails}</p>
        </div>
      );
    }
    if (content.type === 'video') {
      return (
        <video src={content.videoUrl} className="w-full h-full object-cover" />
      );
    }
    return (
      <img src={content.content} alt="Content" className="w-full h-full object-cover" />
    );
  };

  return (
    <div
      className={`aspect-square flex items-center justify-center relative ${
        isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
      }`}
      onDragOver={content && content.isCustom ? handleDragOver : undefined}
      onDrop={content && content.isCustom ? handleDrop : undefined}
      onDragStart={(e) => {
        if (content && content.isCustom) {
          e.dataTransfer.setData('text/plain', index.toString());
          onDragStart(index);
        } else {
          e.preventDefault();
        }
      }}
      draggable={content && content.isCustom}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {renderContent()}
      {showTrashButton && (
        <button 
          className={`absolute top-2 right-2 p-1 transition-opacity duration-300 ease-in-out ${
            isHovered ? 'opacity-80' : 'opacity-0'
          } hover:opacity-80`}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(index);
          }}
        >
          <TrashIcon className="w-6 h-6 text-white" />
        </button>
      )}
    </div>
  );
});

export default GridItem;