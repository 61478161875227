import React from 'react';

const FirstUploadPopup = ({ onClose, isDarkMode }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`bg-white p-6 rounded-lg shadow-xl max-w-sm text-center ${isDarkMode ? 'text-black' : 'text-black'}`}>
        <h2 className="text-xl font-bold mb-4">Upload Information</h2>
        <p className="mb-6">You can upload up to 6 images at a time.</p>
        <button 
          onClick={onClose}
          className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default FirstUploadPopup;