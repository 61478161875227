import React from 'react';

const ErrorPopup = ({ message, onClose, isDarkMode, customAction }) => {
  const handleClose = () => {
    onClose();
    if (customAction) {
      customAction();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`bg-white p-6 rounded-lg shadow-xl max-w-sm text-center ${isDarkMode ? 'text-black' : ''}`}>
        <h2 className="text-xl font-bold mb-4 text-red-600">Error</h2>
        <p className="mb-6">{message}</p>
        <button 
          onClick={handleClose}
          className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ErrorPopup;