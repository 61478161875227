import React, { useState } from 'react';
import { ReactComponent as ThemeModeIcon } from '../src/assets/icons/dark-mode (1).svg';
import { ReactComponent as UndoIcon } from '../src/assets/icons/undo.svg';
import { ReactComponent as RedoIcon } from '../src/assets/icons/redo.svg';
import { ReactComponent as LogOutIcon } from '../src/assets/icons/exit.svg';

const Icon = ({ component: Component, isDarkMode, className = '' }) => (
  <div className={`w-4 h-4 mr-2 ${className}`}>
    <Component className={`w-full h-full ${
      isDarkMode 
        ? 'fill-[#A8A8A8] group-hover:fill-white' 
        : 'fill-[#737373] group-hover:fill-black'
    }`} />
  </div>
);

const BottomMenuButtons = ({ isDarkMode, toggleTheme, handleUndo, handleRedo, canUndo, canRedo, handleLogout }) => {
  const [isUndoAnimating, setIsUndoAnimating] = useState(false);
  const [isRedoAnimating, setIsRedoAnimating] = useState(false);

  const buttonClass = `
    flex-1 py-5 text-sm font-medium flex items-center justify-center group
    ${isDarkMode ? 'text-[#A8A8A8] hover:text-white' : 'text-[#737373] hover:text-black'}
    sm:border-b-2 border-transparent ${isDarkMode ? 'hover:border-white' : 'hover:border-black'}
    transition-all duration-200 ease-in-out
  `;

  const handleUndoClick = () => {
    if (canUndo) {
      setIsUndoAnimating(true);
      handleUndo();
      setTimeout(() => setIsUndoAnimating(false), 300);
    }
  };

  const handleRedoClick = () => {
    if (canRedo) {
      setIsRedoAnimating(true);
      handleRedo();
      setTimeout(() => setIsRedoAnimating(false), 300);
    }
  };

  return (
    <div className={`flex sm:border-b ${isDarkMode ? 'sm:border-gray-700' : 'border-gray-200'}`}>
      <button
        className={buttonClass}
        onClick={toggleTheme}
      >
        <Icon component={ThemeModeIcon} isDarkMode={isDarkMode} />
        <span className="sm:inline hidden">{isDarkMode ? 'LIGHT MODE' : 'DARK MODE'}</span>
      </button>
      <button
        className={`${buttonClass} ${!canUndo ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={handleUndoClick}
        disabled={!canUndo}
      >
        <Icon 
          component={UndoIcon} 
          isDarkMode={isDarkMode} 
          className={isUndoAnimating ? 'rotate-360-ccw' : ''}
        />
        <span className="sm:inline hidden">UNDO</span>
      </button>
      <button
        className={`${buttonClass} ${!canRedo ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={handleRedoClick}
        disabled={!canRedo}
      >
        <Icon 
          component={RedoIcon} 
          isDarkMode={isDarkMode} 
          className={isRedoAnimating ? 'rotate-360-cw' : ''}
        />
        <span className="sm:inline hidden">REDO</span>
      </button>
      <button
        className={buttonClass}
        onClick={handleLogout}
      >
        <Icon component={LogOutIcon} isDarkMode={isDarkMode} />
        <span className="sm:inline hidden">LOG OUT</span>
      </button>
    </div>
  );
};

export default BottomMenuButtons;