import * as tf from '@tensorflow/tfjs';
import * as mobilenet from '@tensorflow-models/mobilenet';

const getRandomFactor = () => Math.random() * 0.4 - 0.2;

const ANALYSIS_TIMEOUT = 3000; // Reduced to 3 seconds

const AIReorganize = async ({ gridItems }) => {
  console.log('Starting AI Reorganize');
  const customItems = gridItems.filter(item => item && item.isCustom && item.type !== 'empty');
  if (customItems.length < 2) {
    console.log('Not enough custom items to reorganize');
    return { items: gridItems, changed: false };
  }

  console.log('Loading MobileNet model');
  const model = await mobilenet.load();

  console.log('Analyzing items');
  const analyzedItems = await Promise.all(
    customItems.map(async (item, index) => {
      console.log(`Analyzing item ${index + 1}`);
      try {
        const result = await Promise.race([
          analyzeItem(item, model, index),
          new Promise((_, reject) => 
            setTimeout(() => reject(new Error('Analysis timeout')), ANALYSIS_TIMEOUT)
          )
        ]);
        return { ...result, originalIndex: gridItems.indexOf(item) };
      } catch (error) {
        console.error(`Error analyzing item ${index + 1}:`, error.message);
        return { 
          ...item, 
          error: true, 
          errorMessage: error.message, 
          originalIndex: gridItems.indexOf(item),
          avgColor: getAverageColor(item),
          brightness: getBrightness(getAverageColor(item)),
          isWarm: isWarmColor(getAverageColor(item))
        };
      }
    })
  );

  console.log('Reorganizing items');
  const reorganizedItems = reorganizeItems(analyzedItems, gridItems);
  console.log('AI Reorganize completed');
  
  return { items: reorganizedItems, changed: true };
};

const analyzeItem = async (item, model, index) => {
  const img = new Image();
  img.src = item.type === 'video' ? (item.thumbnail || item.content) : item.content;
  await new Promise((resolve) => { img.onload = resolve; });
  
  const tfImg = tf.browser.fromPixels(img);
  const resized = tf.image.resizeBilinear(tfImg, [112, 112]); // Reduced resolution
  const avgColor = tf.mean(resized, [0, 1]);
  const brightness = tf.mean(avgColor);
  const colorValues = avgColor.arraySync();
  tfImg.dispose();
  resized.dispose();
  
  console.log(`Item ${index + 1} analyzed successfully`);
  return { 
    ...item, 
    avgColor: colorValues,
    brightness: brightness.dataSync()[0],
    isWarm: isWarmColor(colorValues)
  };
};

const getAverageColor = (item) => {
  // Implement a simple color extraction method here
  // This is a placeholder implementation
  return [128, 128, 128]; // Return a neutral gray as fallback
};

const getBrightness = (color) => {
  return (color[0] * 0.299 + color[1] * 0.587 + color[2] * 0.114) / 255;
};

const isWarmColor = (color) => {
  const [r, g, b] = color;
  return r > b;  // Simple warm/cool differentiation
};

const reorganizeItems = (items, allGridItems) => {
  console.log('Starting reorganizeItems');
  const grid = [...allGridItems];
  const customItemIndices = items.map(item => item.originalIndex);
  
  // Simplified sorting
  items.sort((a, b) => {
    if (a.error && !b.error) return 1;
    if (!a.error && b.error) return -1;
    return (b.brightness + getRandomFactor()) - (a.brightness + getRandomFactor());
  });

  const sortedItems = [...items];

  let warmTurn = true;
  sortedItems.forEach((item, index) => {
    if (!item || item.error) {
      console.log(`Skipping item at index ${index} due to error or missing item`);
      return;
    }

    let bestSlot = item.originalIndex;
    let maxScore = -Infinity;

    customItemIndices.forEach(targetIndex => {
      let contrastScore = getRandomFactor() * 2000 - index * 100; // Simplified scoring

      if (contrastScore > maxScore) {
        maxScore = contrastScore;
        bestSlot = targetIndex;
      }
    });

    if (bestSlot !== item.originalIndex) {
      const temp = grid[bestSlot];
      grid[bestSlot] = grid[item.originalIndex];
      grid[item.originalIndex] = temp;
    }

    warmTurn = !warmTurn;
  });

  console.log('Reorganization completed');
  return grid;
};

const getNeighbors = (index, grid) => {
  const neighbors = [];
  const row = Math.floor(index / 3);
  const col = index % 3;

  if (row > 0) neighbors.push(grid[index - 3]); // top
  if (row < 3) neighbors.push(grid[index + 3]); // bottom
  if (col > 0) neighbors.push(grid[index - 1]); // left
  if (col < 2) neighbors.push(grid[index + 1]); // right

  return neighbors.filter(n => n !== null);
};

const calculateContrast = (item1, item2) => {
  const colorDiff = item1.avgColor.map((c, i) => Math.abs(c - item2.avgColor[i]));
  const brightnessDiff = Math.abs(item1.brightness - item2.brightness);
  let contrastScore = colorDiff.reduce((a, b) => a + b, 0) + brightnessDiff;

  // Boost contrast score for complementary colors
  if (isComplementary(item1.avgColor, item2.avgColor)) {
    contrastScore *= 1.5;
  }

  return contrastScore;
};

const isComplementary = (color1, color2) => {
  const [r1, g1, b1] = color1;
  const [r2, g2, b2] = color2;
  const threshold = 100;  // Adjust this value to fine-tune complementary detection

  return Math.abs(r1 - r2) > threshold && 
         Math.abs(g1 - g2) > threshold && 
         Math.abs(b1 - b2) > threshold;
};

export default AIReorganize;