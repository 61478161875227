import React, { useState, useRef } from 'react';
import Lottie from 'lottie-react';
import { ReactComponent as RefreshFeedIcon } from '../src/assets/icons/grid (2).svg';
import { ReactComponent as AddCellIcon } from '../src/assets/icons/iconmonstr-plus-2.svg';
import { ReactComponent as AIReorderIcon } from '../src/assets/icons/magic-wand.svg';
import syncAnimation from '../src/assets/icons/sync.json';
import { ReactComponent as ShuffleIcon } from '../src/assets/icons/shuffle.svg';

const Icon = ({ component: Component, isDarkMode }) => (
  <Component className={`w-4 h-4 mr-2 ${isDarkMode ? 'fill-[#A8A8A8] group-hover:fill-white' : 'fill-[#737373] group-hover:fill-black'}`} />
);

const AnimatedIcon = ({ isDarkMode }) => (
  <div className={`sync-icon ${isDarkMode ? 'dark' : 'light'}`}>
    <Lottie 
      animationData={syncAnimation} 
      loop={true}
      autoplay={true}
      style={{ width: '1rem', height: '1rem', marginRight: '0.5rem' }}
      rendererSettings={{ 
        preserveAspectRatio: 'xMidYMid slice'
      }}
    />
  </div>
);

const MenuButtons = ({ 
  isDarkMode, 
  imagesLoaded, 
  loadOrRefreshFeed, 
  handleAIReorganize, 
  isProcessing, 
  isLoading, 
  isRefreshing, 
  hasEnoughCustomImages, 
  onAddMedia,
  hasShownUploadInfo,
  setHasShownUploadInfo
}) => {
  const fileInputRef = useRef(null);
  const [shuffleButtonText, setShuffleButtonText] = useState('AI SHUFFLE');

  const buttonClass = `
    flex-1 py-5 text-sm font-medium flex items-center justify-center group
    ${isDarkMode ? 'text-[#A8A8A8] hover:text-white' : 'text-[#737373] hover:text-black'}
    border-t-2 border-transparent ${isDarkMode ? 'hover:border-white' : 'hover:border-black'}
    transition-all duration-200 ease-in-out
  `;

  const handleAddMediaClick = () => {
    if (!hasShownUploadInfo) {
      setHasShownUploadInfo(true);
    }
    onAddMedia();
  };

const handleShuffleClick = () => {
  if (hasEnoughCustomImages) {
    handleAIReorganize();
  } else {
    setShuffleButtonText('ADD MORE');
    setTimeout(() => setShuffleButtonText('AI SHUFFLE'), 1000);
  }
};

  return (
    <div className={`flex border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
      <button
        className={buttonClass}
        onClick={loadOrRefreshFeed}
        disabled={isLoading || isRefreshing}
      >
{isLoading || isRefreshing ? (
  <>
    <AnimatedIcon isDarkMode={isDarkMode} />
    {isLoading ? 'REFRESHING...' : 'REFRESHING...'}
  </>
) : (
          <>
            <Icon component={RefreshFeedIcon} isDarkMode={isDarkMode} />
            {imagesLoaded ? 'REFRESH FEED' : 'LOAD FEED'}
          </>
        )}
      </button>
      <button
        className={buttonClass}
        onClick={handleAddMediaClick}
      >
        <Icon component={AddCellIcon} isDarkMode={isDarkMode} />
        ADD MEDIA
      </button>
<button
  className={buttonClass}
  onClick={handleShuffleClick}
  disabled={isProcessing}
>
  {isProcessing ? (
    <>
      <AnimatedIcon isDarkMode={isDarkMode} />
      PROCESSING...
    </>
  ) : (
    <>
      <Icon component={ShuffleIcon} isDarkMode={isDarkMode} />
      {shuffleButtonText}
    </>
  )}
</button>
      <input 
        type="file" 
        ref={fileInputRef} 
        className="hidden" 
        onChange={(e) => onAddMedia(e.target.files)} 
        multiple 
        accept="image/*,video/*"
      />
    </div>
  );
};

export default MenuButtons;