import React, { useEffect, useRef, useState } from 'react';

const AIFeedbackPopup = ({ onClose, isDarkMode }) => {
  const popupRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10);

    const closeTimer = setTimeout(() => {
      setIsVisible(false);
    }, 1700);  // Start fading out slightly earlier

    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      clearTimeout(timer);
      clearTimeout(closeTimer);
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (!isVisible) {
      const closeDelay = setTimeout(() => {
        onClose();
      }, 300);  // Match this with the transition duration
      return () => clearTimeout(closeDelay);
    }
  }, [isVisible, onClose]);

  const overlayClass = `fixed inset-0 bg-black bg-opacity-0 flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out ${
    isVisible ? 'opacity-100' : 'opacity-0'
  }`;
  const popupClass = `bg-white bg-opacity-90 p-6 rounded-lg shadow-xl max-w-sm w-full mx-4 text-center transition-all duration-300 ease-in-out ${
    isVisible ? 'opacity-100 transform scale-100' : 'opacity-0 transform scale-95'
  }`;

  return (
    <div className={overlayClass}>
      <div ref={popupRef} className={`${popupClass} ${isDarkMode ? 'text-black' : ''}`}>
        <p className={`text-lg font-medium`}>
          Looking good... Nothing to fix!
        </p>
      </div>
    </div>
  );
};

export default AIFeedbackPopup;